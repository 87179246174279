
import { Divider, Typography, Button, MenuItem, Select, TextField, Chip, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import srLatn from 'date-fns/locale/sr-Latn';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const Partneri = ({user}) => {
    

    const [requests, setRequests] = useState([])
    const [requests1, setRequests1] = useState([])
    const [mailFilt, setMailFilt] = useState('')
    const [mailFilt1, setMailFilt1] = useState('')
    const [pageNumDocs,setPageNumDocs] = useState(0)
    const [pageNumDocs1,setPageNumDocs1] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [hasMore1, setHasMore1] = useState(true)
    const [view, setView] = useState('partneri')
    const [pib, setPib] = useState('')
    const [nazivPart, setNazivPart] = useState('')
    const [tip, setTip] = useState('')
    const [nazivDok, setNazivDok] = useState('')
    const [partner, setPartner] = useState('')
    const [successNewDoc, setSuccessNewDoc] = useState('')
    const [errorNewDoc, setErrorNewDoc] = useState('')
    const [successNewPart, setSuccessNewPart] = useState('')
    const [errorNewPart, setErrorNewPart] = useState('')
    const [loadingButTip, setLoadingButTip] = useState(false)
    const [loadingButPart, setLoadingButPart] = useState(false)
    const [partnerInit, setPartnerInit] = useState(false)
    const [tipDocInit, setTipDocInit] = useState(false)

    let timer

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen = (acc) => {
        
        setOpen(true);
    };
    const handleClose = () => {
        setErrorNewPart('')
        setSuccessNewPart('')
        setPib('')
        setNazivPart('')
        setOpen(false)
    }
    const handleClickOpen1 = (acc) => {
        
        setOpen1(true);
    };
    const handleClose1 = () => {
        setSuccessNewDoc('')
        setErrorNewDoc('')
        setTip('')
        setNazivDok('')
        setOpen1(false)
    }

    useEffect(()=>{
        console.log('mfs',partnerInit)
        if(partnerInit){
            timer = setTimeout(()=>{
    
                console.log('mf', pageNumDocs)
                setRequests([])
                setPageNumDocs(0)
                setHasMore(true)
            
            },300)
        }
        
    },[mailFilt])
    
    useEffect(()=>{
        console.log('change',tipDocInit)
        if(tipDocInit){
            timer = setTimeout(()=>{

                console.log('mf1', pageNumDocs1)
                setRequests1([])
                setPageNumDocs1(0)
                setHasMore1(true)
            
            },300)
        }
        
    },[mailFilt1])

    useEffect(()=>{
        console.log('pn',pageNumDocs)
        if(!requests.length && view == 'partneri'){

            fetchData()
            setPartnerInit(true)
        }
    },[requests])
    
    useEffect(()=>{
        console.log('pn1',pageNumDocs1)
        if(!requests1.length && view == 'dokumenti'){

            fetchData1()
            setTipDocInit(true)
        }
    },[requests1])

      
    function fetchData() {
        const pagenum = pageNumDocs + 1
        console.log('fpn',pagenum)
        setPageNumDocs(pagenum)
        console.log('fetching')
        
        console.log(1)
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+user.token);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch("partners.php?pagenum="+pageNumDocs+"&mail="+mailFilt, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(!result.length){
                    setHasMore(false)
                }else{
                    setRequests(prev => [...prev, ...result])
                }

            })
            .catch(error => console.log('error', error));
        
    }

    function fetchData1() {
        const pagenum = pageNumDocs1 + 1
        console.log('fpn',pagenum)
        setPageNumDocs1(pagenum)
        console.log('fetching')
        
        console.log(1)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+user.token);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch("documenttypes.php?pagenum="+pageNumDocs1+"&mail="+mailFilt1+"&pib="+partner, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(!result.length){
                    setHasMore1(false)
                }else{
                    setRequests1(prev => [...prev, ...result])
                }

            })
            .catch(error => console.log('error', error));
        
    }

    const savePartner = () => {

        setLoadingButPart(true)

        console.log('fja')

        
        if(pib == "" || nazivPart ==""){
            setErrorNewPart('Potrebno je popuniti sva polja!')
            setLoadingButPart(false)
            return false;
        }
        if(pib.length != 8 && pib.length != 13 ){
            setErrorNewPart('PIB mora sadržati 8 ili 13 karaktera!')
            setLoadingButPart(false)
            return false;
        }
        setErrorNewPart('')
        console.log(pib, nazivPart)
        
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.token);



        var raw = JSON.stringify({
        "pib": pib,
        "name":nazivPart
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("partners.php", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.id == 1){
            setRequests(prev => [{UserID: user.data.id, PIB: pib, Naziv: nazivPart}, ...prev])
            
            }
            setPib('')
            setNazivPart('')
            setLoadingButPart(false)
            setOpen(false)

        })
        .catch(error => {
            setLoadingButPart(false)
            console.log('error', error)
        });
    }

    const saveTipDoc = () => {
        setLoadingButTip(true)

        if(tip == "" || nazivDok ==""){
            setErrorNewDoc('Potrebno je popuniti sva polja!')
            setLoadingButTip(false)
            return false;
        }
        if(tip.length < 4 ){
            setErrorNewDoc('Šifra dokumenta mora sadržati 4 karaktera!')
            setLoadingButTip(false)
            return false;
        }
        setErrorNewDoc('')
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.token);

        var raw = JSON.stringify({
        "pib": partner,
        "name":nazivDok,
        "doctype":tip

        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("documenttypes.php", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.id == 1){

                setRequests1(prev => [{UserID: user.data.id, PIB: partner, Naziv: nazivDok, TipDok:tip}, ...prev])
                
            }
            setTip('')
                setNazivDok('')
                setLoadingButTip(false)
                setOpen1(false)

        })
        .catch(error => {
            console.log('error', error)
            setLoadingButTip(false)
        });
    }

    const editDocs = (i) => {
        setPartner(i.PIB)
        setPageNumDocs1(0)
        setTipDocInit(false)
        setRequests1([])
        setMailFilt1('')
        setView('dokumenti')

    }

  return (
    <>
    <div 
    style={view != "partneri" ? {display:'none',height:'100%',
    overflow:"auto"} :{
    
     height:'100%',
     overflow:"auto"
    //   flexDirection:'column'
    }}
    >
        <div>
            <Typography variant="h4" gutterBottom>
                Partneri
            </Typography>
        </div>
        <div style={window.outerWidth < 991 ? {height:60, display:'flex', alignItems:'center'} : {height:60, display:'flex', alignItems:'center',paddingRight:15}}>
            <div style={{flex:1}}>
                <TextField id="outlined-basic" label="Pretraga po PIB-u ili Nazivu" variant="outlined"  value={mailFilt}
            onChange={(event) => {
                clearTimeout(timer)
                setMailFilt(event.target.value);
            }} />
            </div>
            {window.outerWidth < 991 &&

        <Button  onClick={handleClickOpen}  variant="contained">
                        <AddIcon />
                        </Button>
                
            }
            {window.outerWidth >= 991 &&
                <Button variant="outlined" onClick={handleClickOpen} color="primary">
                    Novi partner
                </Button>
            }
        </div>
        <div id="datadiv" style={{
            // flex:1
            height:'calc(100% - 115px)',
            overflow:'auto'

            }}>
            <InfiniteScroll
                dataLength={requests.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Učitavanje...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                    <b>Nema više rezultata!</b>
                    </p>
                }
                scrollableTarget="datadiv"
                >
                {requests.map((i, index) =>
                {
                    if(window.outerWidth < 991){
                        return (
                            <div key={i.UserID+i.PIB}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                                
                                <div style={{width:100}}>{i.PIB}</div>
                                <div style={{width:250}}>{i.Naziv}</div>
                                
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15}}>
                                    {/* {
                                        (i.Status == null || i.Status == 'O') &&
                                        <Button variant="contained" onClick={()=>{sendVerifMail(i)}} color="success">
                                        Prihvati
                                        </Button>
                                    }
                                    { i.Status == null && */}
                                        <Button variant="contained"  onClick={()=>{editDocs(i)}} >
                                            <EditIcon />
                                        </Button>
        
                                    {/* } */}
                                </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }else{
                        return (
                            <div key={i.UserID+i.PIB}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                                
                                <div style={{width:100}}>{i.PIB}</div>
                                <div style={{width:250}}>{i.Naziv}</div>
                                
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15, paddingRight:15}}>
                                    {/* {
                                        (i.Status == null || i.Status == 'O') &&
                                        <Button variant="contained" onClick={()=>{sendVerifMail(i)}} color="success">
                                        Prihvati
                                        </Button>
                                    }
                                    { i.Status == null && */}
                                        <Button variant="contained" onClick={()=>{editDocs(i)}} >
                                        Izmeni
                                        </Button>
        
                                    {/* } */}
                                </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }

                }
                )}
                </InfiniteScroll>
        </div>
        <Dialog
            fullScreen = {window.outerWidth < 991 ? true: false}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Novi Partner
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent style={window.outerWidth < 991 ? {}: {minWidth:500}} dividers>
                <TextField style={{width:'100%', marginBottom:20}} onKeyPress={(event)=>{
                    if (!/[0-9]/.test(event.key)) {
                    // if (!/^(?:[A-Za-z]+|\d+)$/.test(event.key)) {
                        event.preventDefault();
                      }
                }} value={pib} 
                onChange={(event)=>{if(event.target.value.length <= 13){setPib(event.target.value)}}} label="PIB" variant="outlined"/>
                <TextField style={{width:'100%',marginBottom:20}} label="Naziv" value={nazivPart} onChange={(event)=>{setNazivPart(event.target.value)}} variant="outlined" />
                {errorNewPart.length > 0 && <Alert style={{width:'100%',marginBottom:20 }} severity="error">{errorNewPart}</Alert>}
                {successNewPart.length > 0 && <Alert style={{width:'100%',marginBottom:20}} severity="success">{successNewPart}</Alert>}
            </DialogContent>
            <DialogActions>
            <LoadingButton loading={loadingButPart} 
                // loadingPosition="start"
                // startIcon={<SaveIcon />}
                onClick={savePartner}>
                Snimi promene
            </LoadingButton>
            </DialogActions>
        </Dialog>
    </div>
    <div 
    style={
        // flex:1
        view == "partneri" ? {display:'none',height:'100%',
        overflow:"auto"} :{
        
         height:'100%',
         overflow:"auto"
        //   flexDirection:'column'
        }}
    >
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{flex:1, display:'flex', height:60, alignItems:'center', fontSize: 30, fontWeight:400}}>
                    Tipovi dokumenata
            </div>
            <CloseIcon fontSize="large" style={{cursor:'pointer'}} onClick={()=>{
                setPartner('')
                
                setView('partneri')
            }} />
        </div>
        <div style={window.outerWidth < 991 ? {height:60, display:'flex', alignItems:'center'} : {height:60, display:'flex', alignItems:'center',paddingRight:15}}>
            <div style={{flex:1, height:60}}>
                <TextField id="outlined-basic" label="Pretraga po Šifri ili Nazivu" variant="outlined"  value={mailFilt1}
            onChange={(event) => {
                clearTimeout(timer)
                setMailFilt1(event.target.value);
            }} />
            </div>
            
            {window.outerWidth < 991 &&

            <Button variant="contained" onClick={handleClickOpen1}>
                <AddIcon />
            </Button>
            }
            {window.outerWidth >= 991 &&
                <Button variant="outlined" onClick={handleClickOpen1} color="primary">
                    Novi dokument
                </Button>
            }
        </div>
        <div id="datadiv" style={ {height:'calc(100% - 120px)',
            overflow:'auto'}
            

            }>
            <InfiniteScroll
                dataLength={requests1.length} //This is important field to render the next data
                next={fetchData1}
                hasMore={hasMore1}
                loader={<h4>Učitavanje...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                    <b>Nema više rezultata!</b>
                    </p>
                }
                scrollableTarget="datadiv"
                >
                {requests1.map((i, index) => 
                {
                    if(window.outerWidth < 991){
                        return (
                            <div key={i.PIB+i.TipDok}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                                
                                <div style={{width:100}}>{i.TipDok}</div>
                                <div style={{width:250}}>{i.Naziv}</div>
                                
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15}}>
                                    {/* {
                                        (i.Status == null || i.Status == 'O') &&
                                        <Button variant="contained" onClick={()=>{sendVerifMail(i)}} color="success">
                                        Prihvati
                                        </Button>
                                    }
                                    { i.Status == null && */}
                                        {/* <Button variant="outlined" onClick={()=>{}} color="error">
                                        Odbaci
                                        </Button> */}
        
                                    {/* } */}
                                </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }else{
                        return (
                            <div key={i.PIB+i.TipDok}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                                
                                <div style={{width:100}}>{i.TipDok}</div>
                                <div style={{width:250}}>{i.Naziv}</div>
                                
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15, paddingRight:15}}>
                                    {/* {
                                        (i.Status == null || i.Status == 'O') &&
                                        <Button variant="contained" onClick={()=>{sendVerifMail(i)}} color="success">
                                        Prihvati
                                        </Button>
                                    }
                                    { i.Status == null && */}
                                        {/* <Button variant="outlined" onClick={()=>{}} color="error">
                                        Odbaci
                                        </Button> */}
        
                                    {/* } */}
                                </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }

                }
                )}
                </InfiniteScroll>
        </div>
        <Dialog
            fullScreen = {window.outerWidth < 991 ? true: false}
            onClose={handleClose1}
            aria-labelledby="customized-dialog-title"
            open={open1}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Novi tip dokumenta
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose1}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent style={window.outerWidth < 991 ? {}: {minWidth:500}} dividers>
                <TextField style={{width:'100%', marginBottom:20}} value={tip}
                onKeyPress={(event)=>{
                    if (!/^(?:[A-Za-z]+|\d+)$/.test(event.key)) {
                            event.preventDefault();
                    }
                }}
                onChange={(event)=>{if(event.target.value.length <= 4){setTip(event.target.value)}}} label="Šifra dokumenta" variant="outlined"/>
                <TextField style={{width:'100%',marginBottom:20}} label="Naziv" value={nazivDok} onChange={(event)=>{setNazivDok(event.target.value)}} variant="outlined" />
                {errorNewDoc.length > 0 && <Alert style={{width:'100%',marginBottom:20 }} severity="error">{errorNewDoc}</Alert>}
                {successNewDoc.length > 0 && <Alert style={{width:'100%',marginBottom:20}} severity="success">{successNewDoc}</Alert>}

            </DialogContent>
            <DialogActions>
            <LoadingButton loading={loadingButTip} 
        //     loadingPosition="start"
        // startIcon={<SaveIcon />}
         onClick={saveTipDoc}>
                Snimi promene
            </LoadingButton>
            </DialogActions>
        </Dialog>
    </div>
    </>
  )
}

export default Partneri