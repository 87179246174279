
import { Divider, Typography, Button, MenuItem, Select, TextField, Chip, Snackbar, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import srLatn from 'date-fns/locale/sr-Latn';
import CheckIcon from '@mui/icons-material/Check';


const Zahtevi = ({user}) => {

    const [requests, setRequests] = useState([])
    const [mailFilt, setMailFilt] = useState('')
    const [pageNumDocs,setPageNumDocs] = useState(0)
    
    const [hasMore, setHasMore] = useState(true)
    const [docsInit, setDocsInit] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [snackType, setSnackType] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    let timer

    useEffect(()=>{
        if(docsInit){
            timer = setTimeout(()=>{
    
                console.log('mf', pageNumDocs)
                setRequests([])
                setPageNumDocs(0)
                setHasMore(true)
            
            },300)
        }
        
    },[mailFilt])

    useEffect(()=>{
        console.log('pn',pageNumDocs)
        if(!requests.length){

            fetchData()
        }
    },[requests])

      
    function fetchData() {
        const pagenum = pageNumDocs + 1
        console.log('fpn',pagenum)
        setPageNumDocs(pagenum)
        console.log('fetching')
        
        console.log(1)
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+user.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch("requests.php?pagenum="+pageNumDocs+"&mail="+mailFilt, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDocsInit(true)
                
                if(!result.length){
                    setHasMore(false)
                }else{
                    setRequests(prev => [...prev, ...result])
                }

            })
            .catch(error => console.log('error', error));
        
    }

    const sendVerifMail = (acc) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+user.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
      
        fetch("verification.php?vc="+acc.VerifikacioniKod+"&userid="+acc.RegID, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)

            })
            .catch(error => console.log('error', error));
    }

    
    const approveReq = (acc) => {
        console.log(acc)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.token);

        var raw = JSON.stringify({
        "type": "approve",
        "regid":acc.RegID
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("requests.php", requestOptions)
        .then(response => response.json())
        .then(result => {

            if(result.type == 'success'){
                setMessage(result.message)
                setSnackType('success')
                setOpenSnack(true)
                setRequests(prev => {
                    return prev.map(item => {
                        if(item.RegID == acc.RegID)
                            item.Status = 'R'
                        return item
                    })
                })
            }else{
                setMessage(result.message)
                setSnackType('error')
                setOpenSnack(true)
            }

            

        })
        .catch(error => console.log('error', error));
    }
    
    const rejectReq = (acc) => {
        console.log(acc)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.token);

        var raw = JSON.stringify({
        "type": "reject",
        "regid":acc.RegID
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("requests.php", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(acc, 'unutra')
            if(result.type == 'success'){
                setMessage(result.message)
                setSnackType('success')
                setOpenSnack(true)
                setRequests(prev => {
                    return prev.map(item => {
                        if(item.RegID == acc.RegID)
                            item.Status = 'O'
                        return item
                    })
                })
            }else{
                setMessage(result.message)
                setSnackType('error')
                setOpenSnack(true)
            }

        })
        .catch(error => console.log('error', error));
    }

  return (
    <div 
        style={{
            // display:'flex',
             height:'100%',
             overflow:"auto"
            //   flexDirection:'column'
            }}
        >
        <div>
            <Typography variant="h4" gutterBottom>
                Zahtevi
            </Typography>
        </div>
        <div style={window.outerWidth < 991 ? {height:60, width:'100%'} : {height:60}}>
            <TextField id="outlined-basic" style={window.outerWidth < 991 ? {width:'100%'} : {}} label="Pretraga po PIB-u ili Nazivu" variant="outlined"  value={mailFilt}
        onChange={(event) => {
            clearTimeout(timer)
            setMailFilt(event.target.value);
        }} />
        </div>
        <div id="datadiv" style={{
            // flex:1
            height:'calc(100% - 115px)',
            overflow:'auto'

            }}>
            <InfiniteScroll
                dataLength={requests.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Učitavanje...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                    <b>Nema više rezultata!</b>
                    </p>
                }
                scrollableTarget="datadiv"
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
                >
                {requests.map((i, index) => {
                    if(window.outerWidth < 991 ){
                        return (
                            <div key={i.RegID}>
                            <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center', flexWrap:'wrap'}} >
                            <div style={{minWidth:100, maxWidth:100}}>{i.PIB}</div>
                            <div style={{minWidth:150, flexGrow:1,textAlign:'right'}}>{
                                i.Status == null && <Chip label="ZAHTEV" variant="outlined" color="primary" />
                            }
                            {
                                i.Status == 'R' && <Chip label="PRIHVAĆEN" variant="outlined" color="success" />
                            }
                            {
                                i.Status == 'O' && <Chip label="ODBAČEN" variant="outlined" color="error" />
                            }
                            </div>
                            <div style={{minWidth:250, maxWidth:250}}>{i.Username}</div>
                            <div style={{minWidth:250, maxWidth:250}}>{i.Naziv}</div>
                            <div style={{flex:1, justifyContent:'end', display:'flex', gap:15}}>
                                {
                                    (i.Status == null || i.Status == 'O') &&
                                    <Button variant="contained" onClick={()=>{approveReq(i)}} color="success">
                                    <CheckIcon />
                                    </Button>
                                }
                                { i.Status == null &&
                                    <Button variant="outlined" onClick={()=>{rejectReq(i)}} color="error">
                                    <CloseIcon />
                                    </Button>
    
                                }
                            </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }else{
                        return (
                            <div key={i.RegID}>
                            <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                            <div style={{minWidth:250, maxWidth:250}}>{i.Username}</div>
                            <div style={{minWidth:100, maxWidth:100}}>{i.PIB}</div>
                            <div style={{minWidth:250, maxWidth:250}}>{i.Naziv}</div>
                            <div style={{minWidth:250, maxWidth:250}}>{
                                i.Status == null && <Chip label="ZAHTEV" variant="outlined" color="primary" />
                            }
                            {
                                i.Status == 'R' && <Chip label="PRIHVAĆEN" variant="outlined" color="success" />
                            }
                            {
                                i.Status == 'O' && <Chip label="ODBAČEN" variant="outlined" color="error" />
                            }
                            </div>
                            <div style={{flex:1, justifyContent:'end', display:'flex', gap:15, paddingRight:15}}>
                                {
                                    (i.Status == null || i.Status == 'O') &&
                                    <Button variant="contained" onClick={()=>{approveReq(i)}} color="success">
                                    Prihvati
                                    </Button>
                                }
                                { i.Status == null &&
                                    <Button variant="outlined" onClick={()=>{rejectReq(i)}} color="error">
                                    Odbaci
                                    </Button>
    
                                }
                            </div>
                            </div>
                            <Divider></Divider>
                            </div>
                        )
                    }
                    
                })}
                </InfiniteScroll>
        </div>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} severity={snackType} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    </div>
    
  )
}

// {"RegID":201,"Username":"milan99@email.com","Password":"milan123","PIB":"121456789","Naziv":"Milanova kompanija 99","Telefon":"12345678","VerifikacioniKod":"c2caee8967828a058c4ac5ad981aa311c599","PasswordPant":"Sm1Lsf","Status":null,"VrijemeUpisa":"2023-09-08 08:20:59.047","Imei":"","LinkUserID":null,"RowNum":"1"}

export default Zahtevi