import logo from './LogoScan-cropped-30.png';
import logologin from './LogoScan-cropped-50.png';

import './App.css';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import Zahtevi from './Zahtevi';
import Nalozi from './Nalozi';
import Partneri from './Partneri';
import { useEffect, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Alert, Button, Drawer, FormControl, IconButton, InputAdornment, InputLabel, ListItemIcon, OutlinedInput, TextField, Typography } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function AdminApp({ user, setUser }) {

  const [view, setView] = useState('zahtevi')
  const [width, setWidth] = useState(window.outerWidth)
  const [drawerOpen, setDrawerOpen] = useState(false)


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };
  return (
    <>
      <div style={{ height: '50px', display: 'flex', alignItems: 'center', padding: 10 }}>
        {width < 991 && <MenuIcon style={{ marginRight: 15 }} onClick={() => { setDrawerOpen(true) }} fontSize='large' />}
        <div style={{ flexGrow: 1 }}><img src={logo} style={{ height: '30px' }} alt="logo" /></div>
        <LogoutIcon sx={{ fontSize: 30, cursor: 'pointer' }} onClick={() => {
          localStorage.removeItem('user')
          setUser(null)
        }} />
      </div>
      <div style={{ height: 'calc(100vH - 50px)', display: 'flex' }}>

        <div style={width < 991 ? { display: 'none' } : { width: '200px', padding: 10, height: '100%' }}>
          <Paper style={{ padding: 10, height: '100%' }} elevation={1}>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => { setView('zahtevi') }}>
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Zahtevi" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => { setView('nalozi') }}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Nalozi" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </div>
        <div style={width < 991 ? { width: '100%', padding: 10, height: '100%' } : { width: 'calc(100% - 200px)', padding: 10, height: '100%' }}>
          <Paper className="maincnt" style={width < 991 ? { padding: 15, height: '100%' } : { padding: 30, height: '100%' }} elevation={1}>
            {view == 'nalozi' &&
              <Nalozi user={user} />}

            {view == 'zahtevi' &&
              <Zahtevi user={user} />
            }
          </Paper>
        </div>
      </div>
      <SwipeableDrawer

        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { setView('zahtevi') }}>
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText primary="Zahtevi" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { setView('nalozi') }}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Nalozi" />
              </ListItemButton>
            </ListItem>
          </List>

        </Box>
      </SwipeableDrawer>

    </>

  );
}


function ClientApp({ user, setUser }) {
  return (
    <>
      <div style={{ height: '50px', display: 'flex', alignItems: 'center', padding: 10 }}>
        <div style={{ flexGrow: 1 }}><img src={logo} style={{ height: '30px' }} alt="logo" /></div>
        <LogoutIcon sx={{ fontSize: 30, cursor: 'pointer' }} onClick={() => {
          localStorage.removeItem('user')
          setUser(null)
        }} />
      </div>
      <div style={{ height: 'calc(100vH - 50px)', padding: 10, width: '100%' }}>
        <Paper className="maincnt" style={window.outerWidth < 991 ? { padding: 15, height: '100%' } : { padding: 30, height: '100%' }} elevation={1}>
          <Partneri user={user} />
          {/* <Zahtevi /> */}
        </Paper>
      </div>
    </>

  );
}

function LoginApp({ user, setUser }) {

  const [view, setView] = useState('login')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorLogin, setErrorLogin] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [emailErr, setEmailErr] = useState(false);
  const [email, setEmail] = useState("");
  const [pibErr, setPibErr] = useState(false);
  const [pib, setPib] = useState("");
  const [nazivErr, setNazivErr] = useState(false);
  const [naziv, setNaziv] = useState("");
  const [telErr, setTelErr] = useState(false);
  const [tel, setTel] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [pass, setPass] = useState("");
  const [passdErr, setPassdErr] = useState(false);
  const [passd, setPassd] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false)
  const [errMsg, setErrMsg] = useState({
    "email": "",
    "pib": "",
    "naziv": "Obavezno polje!",
    "telefon": "Obavezno polje!",
    "pass": "Obavezno polje!",
    "passd": ""

  })

  const login = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "username": username,
      "password": password
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("login.php", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.success) {
          localStorage.setItem('user', JSON.stringify(result))
          setUser(result)
        } else {
          setErrorLogin(result.message)
        }
      })
      .catch(error => console.log('error', error));
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const register = async () => {

    const val = validateEmail(email)

    const pibcheck = pib.length != 8 && pib.length != 13
    if (email == "" || pib == "" || naziv == "" || tel == "" || pass == "" || passd == "" || emailErr || pibErr || passdErr || pass != passd || val == null || pibcheck) {
      if (email == "") {
        setEmailErr(true)
        setErrMsg(prev => { return { ...prev, email: 'Obavezno polje!' } })
      }
      if (pib == "") {
        setPibErr(true)
        setErrMsg(prev => { return { ...prev, pib: 'Obavezno polje!' } })
      }
      if (naziv == "") {
        setNazivErr(true)
        setErrMsg(prev => { return { ...prev, naziv: 'Obavezno polje!' } })
      }
      if (tel == "") {
        setTelErr(true)
        setErrMsg(prev => { return { ...prev, telefon: 'Obavezno polje!' } })
      }
      if (pass == "") {
        setPassErr(true)
        setErrMsg(prev => { return { ...prev, pass: 'Obavezno polje!' } })
      }
      if (passd == "") {
        setPassdErr(true)
        setErrMsg(prev => { return { ...prev, passd: 'Obavezno polje!' } })
      }

      if (pass != passd) {
        setPassdErr(true)
        setErrMsg(prev => { return { ...prev, passd: 'Lozinke se ne poklapaju!' } })
      }

      if (val == null) {
        setEmailErr(true)
        setErrMsg(prev => { return { ...prev, email: 'Neispravna email adresa!' } })
      }
      if (pibcheck) {
        setPibErr(true)
        setErrMsg(prev => { return { ...prev, pib: 'PIB mora sadržati 8 ili 13 karaktera!' } })
      }
      console.log('greska')
      return false;
    }
    setLoadingLogin(true)
    console.log('prosao')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "username": email,
      "password": pass,
      "pib": pib,
      "naziv": naziv,
      "telefon": tel
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("register.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result[0].success == -1) {
          // setLoginMessage(result.token)
          if (result[0].message == 'Korisničko ime je zauzeto!') {
            setEmailErr(true)
            setErrMsg(prev => { return { ...prev, email: result[0].message } })
          }
          if (result[0].message == 'Postoji korisik sa unetim PIB-om!') {
            setPibErr(true)
            setErrMsg(prev => { return { ...prev, pib: result[0].message } })
          }
          setLoadingLogin(false)
        } else {
          alert('Zahtev za kreiranje naloga je u obradi!')
          setView('login')
        }

      })
      .catch(error => {
        console.log('error', error)
      });


  }
  return (
    <div style={{ height: '100vH', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
      <Paper className="maincnt" style={window.outerWidth < 991 ? { padding: 15, width: '100%' } : { padding: 30 }} elevation={1}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', marginBottom: 20 }}><img src={logologin} style={{ height: '50px' }} alt="logo" /></div>
          {
            view == 'login' &&
            <>
              <TextField style={{ width: '100%', marginBottom: 20 }} value={username} onKeyUp={(event) => { if (event.keyCode == 13) { login() } }} onChange={(event) => { setUsername(event.target.value) }} label="Korisničko ime" variant="outlined" />
              {/* <TextField style={{width:'100%', marginBottom:20}} label="Lozinka" value={password} onChange={(event)=>{setPassword(event.target.value)}} variant="outlined" /> */}

              <TextField
                label="Lozinka"
                style={{ width: '100%', marginBottom: 20 }}
                value={password} onChange={(event) => { setPassword(event.target.value) }}
                onKeyUp={(event) => { if (event.keyCode == 13) { login() } }}
                InputProps={{
                  type: showPassword ? 'text' : 'password',
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
              {/* <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Lozinka</InputLabel>

                <OutlinedInput
                  id="outlined-adornment-password"
                  style={{ width: '100%', marginBottom: 20 }}
                  value={password} onChange={(event) => { setPassword(event.target.value) }}
                  onKeyUp={(event) => { if (event.keyCode == 13) { login() } }}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Lozinka"
                />
              </FormControl> */}
              <Button variant="contained" style={{ width: '100%', marginBottom: 20 }} onClick={login} >
                Prijavi se
              </Button>
              {errorLogin.length > 0 && <Alert style={{ width: '100%', marginBottom: 20 }} severity="error">{errorLogin}</Alert>}
              <Typography style={{ cursor: 'pointer' }} onClick={() => { setView('register') }} variant='caption'>Kreirajte novi nalog</Typography>
            </>

          }
          {view == 'register' && <>
            <TextField style={{ width: '100%', marginBottom: 20 }} label="Email" error={emailErr} helperText={emailErr ? errMsg.email : ''} FormHelperTextProps={{ error: true }} variant="outlined" value={email} onChange={(ev) => {
              const value = ev.target.value
              setEmail(value);
              setEmailErr(false)
            }}
              onBlur={(evt) => {
                const val = validateEmail(evt.target.value)
                if (val == null) {
                  setEmailErr(true)
                  setErrMsg(prev => { return { ...prev, email: 'Neispravna email adresa!' } })
                }
              }} />
            <TextField style={{ width: '100%', marginBottom: 20 }} label="PIB" error={pibErr} helperText={pibErr ? errMsg.pib : ''} FormHelperTextProps={{ error: true }} variant="outlined" value={pib} onChange={(ev) => {
              const value = ev.target.value
              if (value.length <= 13 && /^[0-9]*$/.test(value)) {
                setPib(value);
                setPibErr(false)
              }
            }}
              onBlur={(evt) => {
                if (evt.target.value.length != 8 && evt.target.value.length != 13) {
                  setPibErr(true)
                  setErrMsg(prev => { return { ...prev, pib: 'PIB mora sadržati 8 ili 13 karaktera!' } })
                }
              }} />
            <TextField style={{ width: '100%', marginBottom: 20 }} error={nazivErr} helperText={nazivErr ? errMsg.naziv : ''} FormHelperTextProps={{ error: true }} label="Naziv firme" onChange={(ev) => {
              const value = ev.target.value
              setNaziv(value);
              setNazivErr(false)
            }} variant="outlined" />
            <TextField style={{ width: '100%', marginBottom: 20 }} error={telErr} helperText={telErr ? errMsg.telefon : ''} FormHelperTextProps={{ error: true }} value={tel} onChange={(ev) => {
              const value = ev.target.value
              setTel(value);
              setTelErr(false)
            }} label="Mobilni telefon" variant="outlined" />
            <TextField
              label="Lozinka"
              style={{ width: '100%', marginBottom: 20 }}
              error={passErr} helperText={passErr ? errMsg.pass : ''} FormHelperTextProps={{ error: true }}
              value={pass} onChange={(ev) => {
                const value = ev.target.value
                setPass(value);
                setPassErr(false)
              }}
              InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
            <TextField
              label="Ponovi lozinka"
              style={{ width: '100%', marginBottom: 20 }}
              error={passdErr} helperText={passdErr ? errMsg.passd : ''} FormHelperTextProps={{ error: true }}
              value={passd} onChange={(ev) => {
                const value = ev.target.value
                setPassd(value);
                setPassdErr(false)
              }}
              onBlur={(evt) => {
                if (evt.target.value != pass) {
                  setPassdErr(true)
                  setErrMsg(prev => { return { ...prev, passd: 'Lozinke se ne poklapaju!' } })
                }
              }}
              // value={password} onChange={(event) => { setPassword(event.target.value) }}
              // onKeyUp={(event) => { if (event.keyCode == 13) { login() } }}
              InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
            <Button variant="contained" style={{ width: '100%', marginBottom: 20 }} onClick={register}>
              Kreiraj nalog
            </Button>
            {/* {errorLogin.length > 0 && <Alert style={{ width: '100%', marginBottom: 20 }} severity="error">{errorLogin}</Alert>} */}
            <Typography style={{ cursor: 'pointer' }} onClick={() => { setView('login') }} variant='caption'>Prijava</Typography>
          </>}

        </div>
      </Paper>
    </div>
  )
}

function App() {

  const [user, setUser] = useState(null)
  const [loaded, setloaded] = useState(false)

  useEffect(() => {
    const usertmp = localStorage.getItem('user')
    if (usertmp != null) {
      setUser(JSON.parse(usertmp))
    }
    setloaded(true)
  }, [])

  return (
    <div style={{ background: '#fafafa', height: '100vH' }}>

      {(user != null && loaded && user.data.admin != 'T') && <ClientApp user={user} setUser={setUser} />}
      {(user != null && loaded && user.data.admin == 'T') && <AdminApp user={user} setUser={setUser} />}
      {user == null && loaded &&
        <LoginApp user={user} setUser={setUser} />

      }



    </div>

    // <div style={{height:'100vH'}}>
    //   <div style={{display:'flex', height:'100%', background:'#fafafa'}}>
    //     <div style={{width:'200px', padding:10, height:'100%'}}><Paper style={{padding:10}} elevation={4}>Hello world</Paper></div>
    //     <div style={{width:'calc(100% - 200px)', padding:10}}>Hello world</div>
    //   </div>
    // </div>
  );
}

export default App;

{/* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */}