
import { Divider, Typography, Button, MenuItem, Select, TextField, Chip, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import srLatn from 'date-fns/locale/sr-Latn';
import toDate from 'date-fns/toDate'
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

const Nalozi = ({user}) => {

    const [requests, setRequests] = useState([])
    const [mailFilt, setMailFilt] = useState('')
    const [pageNumDocs,setPageNumDocs] = useState(0)
    
    const [hasMore, setHasMore] = useState(true)
    const [docsInit, setDocsInit] = useState(false)
    const [status, setStatus] = useState('A')
    const [datePickerShow, setDatePickerShow] = useState(false)
    const [date, setDate] = useState(null)
    const [infoText, setInfoText] = useState('')
    const [userID, setUserID] = useState(null)
    const [successAcc, setSuccessAcc] = useState('')
    const [errorAcc, setErrorAcc] = useState('')

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (acc) => {
        setSuccessAcc('')
        setErrorAcc('')
        setUserID(acc.UserID)
        setStatus(acc.StatusNaloga)
        setDate((acc.DatumBlokade == null ? null : new Date(acc.DatumBlokade)))
        setInfoText((acc.InfoPoruka == null ? '' : acc.InfoPoruka))
        setOpen(true);
    };
    const handleClose = () => {
        setSuccessAcc('')
        setErrorAcc('')
        setOpen(false)
    }

    const saveAcc = () => {
        if(status == 'B' && date == null){
            setErrorAcc('Potrtebno je odabrati datum blokade!')
            return false;
        }
        console.log(userID)
        console.log(status)
        console.log((date == null ? null : format(date,'yyyy-MM-dd HH:mm:ss.SSS')))
        console.log(infoText)

        let datetmp
        if(status == 'A'){
            datetmp = null
        }else{
            datetmp = format(date,'yyyy-MM-dd HH:mm:ss.SSS')
        }

        console.log({
            "userid": userID,
            "status":status,
            "msg":infoText,
            "date":datetmp
    
            })
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.token);

        var raw = JSON.stringify({
        "userid": userID,
        "status":status,
        "msg":infoText,
        "date":datetmp

        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("accounts.php", requestOptions)
        .then(response => response.json())
        .then(result => {
            
            setRequests(prev => {
                return prev.map(item => {
                    if(item.UserID == userID){

                        item.StatusNaloga = status
                        item.InfoPoruka = infoText
                        item.DatumBlokade = datetmp
                    }
                    return item
                })
            })
            setOpen(false)

        })
        .catch(error => console.log('error', error));
        
    };

    let timer

    useEffect(()=>{
        if(docsInit){

            timer = setTimeout(()=>{
    
                console.log('mf', pageNumDocs)
                setRequests([])
                setPageNumDocs(0)
                setHasMore(true)
            
            },300)
        }
        
    },[mailFilt])

    
    useEffect(()=>{
        console.log('pn',pageNumDocs)
        if(!requests.length){

            fetchData()
        }
    },[requests])

    useEffect(()=>{
        if(status == 'A'){
            setDatePickerShow(false)
        }else{
            setDatePickerShow(true)
        }
    },[status])

      
    function fetchData() {
        const pagenum = pageNumDocs + 1
        console.log('fpn',pagenum)
        setPageNumDocs(pagenum)
        console.log('fetching')
        
        console.log(1)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+user.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          
          fetch("accounts.php?pagenum="+pageNumDocs+"&mail="+mailFilt, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDocsInit(true)
                if(!result.length){
                    setHasMore(false)
                }else{
                    setRequests(prev => [...prev, ...result])
                }

            })
            .catch(error => console.log('error', error));
        
    }

    

  return (
    <div 
        style={{
            // display:'flex',
             height:'100%',
             overflow:"auto"
            //   flexDirection:'column'
            }}
        >
        <div>
            <Typography variant="h4" gutterBottom>
                Nalozi
            </Typography>
        </div>
        <div style={window.outerWidth < 991 ? {height:60, width:'100%'} : {height:60}}>
            <TextField id="outlined-basic" style={window.outerWidth < 991 ? {width:'100%'} : {}} label="Pretraga po PIB-u i Nazivu" variant="outlined"  value={mailFilt}
        onChange={(event) => {
            clearTimeout(timer)
            setMailFilt(event.target.value);
        }} />
        </div>
        <div id="datadiv" style={{
            // flex:1
            height:'calc(100% - 115px)',
            overflow:'auto'

            }}>
            <InfiniteScroll
                dataLength={requests.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Učitavanje...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                    <b>Nema više rezultata!</b>
                    </p>
                }
                scrollableTarget="datadiv"
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
                >
                {requests.map((i, index) => {
                    if(window.outerWidth < 991){
                        return (
                            <div key={i.UserID}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center', flexWrap:'wrap'}} >
                                <div style={{minWidth:100, maxWidth:100 }}>{i.PIB}</div>
                                <div style={{minWidth:150, flexGrow:1,textAlign:'right'}}>{(i.StatusNaloga == 'A' ?  <Chip label="AKTIVAN" variant="outlined" color="success" /> : <Chip label="BLOKADA" variant="outlined" color="error" />)}</div>
                                <div style={{minWidth:250, maxWidth:250}}>{i.Username}</div>
                                <div style={{minWidth:250, maxWidth:250}}>{i.PasswordPant}</div>
                                <div style={{minWidth:200, maxWidth:150}}>{i.Naziv}</div>
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15, }}>
                                    <Button variant="contained"  onClick={()=>{handleClickOpen(i)}} >
                                    <EditIcon />
                                    </Button>
                                </div>
                                </div>
                                <Divider style={{width:'100%'}}></Divider>
                            </div>
                        )
                    }else{
                        return (
                            <div key={i.UserID}>
                                <div style={{paddingTop:15, paddingBottom:15, display:'flex', gap:'20px',alignItems:'center'}} >
                                <div style={{minWidth:250, maxWidth:250}}>{i.Username}</div>
                                <div style={{minWidth:100, maxWidth:100}}>{i.PIB}</div>
                                <div style={{minWidth:250, maxWidth:250}}>{i.Naziv}</div>
                                <div style={{minWidth:150, maxWidth:150}}>{(i.StatusNaloga == 'A' ?  <Chip label="AKTIVAN" variant="outlined" color="success" /> : <Chip label="BLOKADA" variant="outlined" color="error" />)}</div>
                                <div style={{minWidth:100, maxWidth:100}}>{i.PasswordPant}</div>
                                <div style={{flex:1, justifyContent:'end', display:'flex', gap:15, paddingRight:15}}>
                                    <Button variant="contained" onClick={()=>{handleClickOpen(i)}} >
                                    Izmeni
                                    </Button>
                                </div>
                                </div>
                                <Divider style={{width:'100%'}}></Divider>
                            </div>
                        )
                    }

                }
                )}
                </InfiniteScroll>
        </div>
        <Dialog
            fullScreen = {window.outerWidth < 991 ? true: false}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Upravljanje nalogom
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent style={window.outerWidth < 991 ? {}: {minWidth:500}} dividers>
                <div style={{display:'flex', gap:20}}>
                    <div style={{flex:1}}>
                        <Select
                            style={{width:'100%'}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Age"
                            onChange={(event)=>{setStatus(event.target.value)}}
                        >
                            <MenuItem value={"A"}>Aktivan</MenuItem>
                            <MenuItem value={"B"}>Blokada</MenuItem>
                        </Select>
                    </div>
                    {   datePickerShow &&

                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={srLatn}>
                                <MobileDateTimePicker value={date} 
                                onChange={(newValue) => setDate(newValue)}
                                />
                            </LocalizationProvider>
                        </div>

                    }
                </div>
                <div style={{marginTop:20, marginBottom:20, width:'100%'}}>
                <TextField
                    style={{width:'100%'}}
                    id="outlined-multiline-static"
                    label="Informaciona poruka"
                    multiline
                    rows={4}
                    value={infoText}
                    onChange={(event)=>{setInfoText(event.target.value)}}
                    />
                </div>
                {errorAcc.length > 0 && <Alert style={{width:'100%' }} severity="error">{errorAcc}</Alert>}
                {successAcc.length > 0 && <Alert style={{width:'100%'}} severity="success">{successAcc}</Alert>}
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={saveAcc}>
                Snimi promene
            </Button>
            </DialogActions>
        </Dialog>
    </div>
    
  )
}

// {"RegID":201,"Username":"milan99@email.com","Password":"milan123","PIB":"121456789","Naziv":"Milanova kompanija 99","Telefon":"12345678","VerifikacioniKod":"c2caee8967828a058c4ac5ad981aa311c599","PasswordPant":"Sm1Lsf","Status":null,"VrijemeUpisa":"2023-09-08 08:20:59.047","Imei":"","LinkUserID":null,"RowNum":"1"}

export default Nalozi